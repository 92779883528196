import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../Config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useSearchParams } from "react-router-dom";
// import Loader from "react-loader-spinner";
import { TailSpin } from "react-loader-spinner";
import { Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";

const School = () => {
  const [data, setData]                 = useState([]);
  const [loading, setLoading]           = useState(true);
  const [currentPage, setCurrentPage]   = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const MySwal = withReactContent(Swal);
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const token  = useSelector((state)=>state.profile.token);


  //const [searchParams] = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams({
    code: "",
    name: "",
    mobile: "",
    email: "",
  });

  useEffect(() => {
    getData();
  }, [code, name, email, mobile]);

  const getData = async () => {
    try {
      let apiUrl = `${API_URL}/schools`;
      const queryParams = new URLSearchParams();
  
      if (code !== "") {
        
        queryParams.append("code", code);
      }
      if (name !== "") {
        queryParams.append("name", name);
      }
      if (email !== "") {
        queryParams.append("email", email);
      }
      if (mobile !== "") {
        queryParams.append("mobile", mobile);
      }
      
      if (queryParams.toString() !== "") {
        apiUrl += `?${queryParams.toString()}`;
      }

        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
      setData(response.data.records);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    /* these are only for get value show in url start here*/ 
    const params = new URLSearchParams(window.location.search);
    params.set(name, value);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
    setSearchParams(params);
    /*End Here - without iske bhi searching work kar raha he*/ 

    if (name === "code") setCode(value);
    else if (name === "name") setName(value);
    else if (name === "mobile") setMobile(value);
    else if (name === "email") setEmail(value);
  };
  
  

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // For Delete
  const handleDelete = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
    try {
      const response = await axios.delete(`${API_URL}/schools/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // alert(response.data.message);
      toast.success(response.data.message);
      getData();
    } catch (error) {
      toast.error(error.message);
    }
   }
  });
  };

  // For Status Change
  const handleStatusChange = async (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You want to change this status!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
    try {
      const response = await axios.get(`${API_URL}/schools/status/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // alert(response.data.message);
      toast.success(response.data.message);
      getData();
    } catch (error) {
      toast.error(error.message);
    }
  }
});
  };

  return (
    <>
      {/* Loader */}
      {loading && (
        <div className="loader-container d-flex justify-content-center">
          {/* Use the specific loader component */}
          <TailSpin color="#00BFFF" height={280} width={180} />
        </div>
      )}
      {!loading && (
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">Schools</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/school">Shools</Link>
                    </li>
                    <li className="breadcrumb-item active">All Schools</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="student-group-form">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="School Code ..." name="code" value={code} onChange={(e)=>handleChange(e)}/>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="School Name ..." name="name" value={name} onChange={(e)=>handleChange(e)}/>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="School Phone ..." name="mobile" value={mobile} onChange={(e)=>handleChange(e)}/>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="School Email ..." name="email" value={email} onChange={(e)=>handleChange(e)}/>
                </div>
              </div>
              
              {/* <div className="col-lg-2">
                <div className="search-student-btn">
                  <button type="btn" className="btn btn-primary">
                    Search
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table comman-shadow">
                <div className="card-body">
                  <div className="page-header">
                    <div className="row align-items-center">
                      <div className="col">
                        <h3 className="page-title">Schools</h3>
                      </div>
                      <div className="col-auto text-end float-end ms-auto download-grp">
                        <a
                          href="/school"
                          className="btn btn-outline-gray me-2 active"
                        >
                          <i className="feather-list" />
                        </a>
                        <Link
                          href="/school"
                          className="btn btn-outline-gray me-2"
                        >
                          <i className="feather-grid" />
                        </Link>
                        <a
                          href="/school"
                          className="btn btn-outline-primary me-2"
                        >
                          <i className="fas fa-download" /> Download
                        </a>
                        <a href="add-school" className="btn btn-primary">
                          <i className="fas fa-plus" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                      <thead className="student-thread">
                        <tr>
                          <th>
                            <div className="form-check check-tables">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue="something"
                              />
                            </div>
                          </th>
                          <th>#</th>
                          <th>School Code</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Mobile</th>
                          <th>Address</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Pincode</th>
                          <th>Status</th>
                          <th className="text-end">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems?.map((v, index) => (
                          <tr key={v.id}>
                            <td>
                              <div className="form-check check-tables">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue="something"
                                />
                              </div>
                            </td>
                            <td>{++index}</td>
                            <td>{v.code}</td>
                            <td>
                              <h2 className="table-avatar">
                                <a
                                  href="#!"
                                  className="avatar avatar-sm me-2"
                                >
                                  {v.logo.trim() !== "" ? (
                                    <img
                                      className="avatar-img rounded-circle"
                                      src={v.logo}
                                      alt="User"
                                    />
                                  ) : (
                                    <img
                                      className="avatar-img rounded-circle"
                                      src="admin/assets/img/dummy.png"
                                      alt="school"
                                    />
                                  )}
                                </a>
                                <a href="#!">{v.name}</a>
                              </h2>
                            </td>
                            <td>{v.email}</td>
                            <td>{v.mobile}</td>
                            <td>{v.address}</td>
                            <td>{v.city}</td>
                            <td>{v.state}</td>
                            <td>{v.pincode}</td>
                            <td>
                              {v.status == 1 ? (
                                <button
                                  onClick={() => handleStatusChange(v.id)}
                                  className="btn btn-success"
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  onClick={() => handleStatusChange(v.id)}
                                  className="btn btn-danger"
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                            <td className="text-end">
                              <div className="actions ">
                                <a
                                  href={`school/${v.id}`}
                                  className="btn btn-sm bg-success text-white"
                                >
                                  <i className="feather-edit" />
                                </a>
                                <button
                                  onClick={() => handleDelete(v.id)}
                                  className="btn btn-sm bg-danger text-white me-2 "
                                >
                                  <i className="feather-trash" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination */}
                  <Pagination>
                    {Array.from({
                      length: Math.ceil(data.length / itemsPerPage),
                    }).map((_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default School;
