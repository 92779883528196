import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../Config";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const AddSchool = () => {
  const token  = useSelector((state)=>state.profile.token);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [file, setFile] = useState("");
  
  async function saveShool()
  {
    // console.log(name, email, password, confirmPassword, mobile, address, city, state, pincode, file);
    const formData = new FormData();
    formData.append("logo", file);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("password_confirmation", confirmPassword);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("pincode", pincode);
    try {
      const response = await axios.post(`${API_URL}/schools`, formData,{
        headers : {
            Authorization  : `Bearer ${token}`,
            "Content-Type" : "multipart/form-data", 
            "Accept"       : "application/json", 
        }
    });
      console.log('Data is successfully store.', response.data);
      if(response.data.status === true)
      {
        // alert("Data is successfully stored.");
        toast.success("Data is successfully stored.");
        navigate("/school");
      }
      else{
        // alert(response.data.error);
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error('Error making POST request:', error);
      toast.error("Data failed.");
    }
  }
  return (
    <>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Add Schools</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link href="/school">Schools</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Schools</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card comman-shadow">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title student-info">
                        Schools Information{" "}
                        <span>
                          <a href="#!">
                            <i className="feather-more-vertical" />
                          </a>
                        </span>
                      </h5>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>Name <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" placeholder="Enter Name" name="name" value={name} onChange={(e)=>{setName(e.target.value)}} />
                        {/* {errors.name && <p className='text-danger'>Name is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>Mobile <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="number" placeholder="Enter Mobile Number" name="mobile" value={mobile} onChange={(e)=>{setMobile(e.target.value)}} />
                        {/* {errors.mobile && <p className='text-danger'>Mobile Number is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Email <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="email" placeholder="Enter Email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                        {/* {errors.email && <p className='text-danger'>Email is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Password <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="password" placeholder="Enter Password" name="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
                        {/* {errors.password && <p className='text-danger'>Password is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Confirm Password<span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" placeholder="Enter Confirm Password" name="confirmPassword" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}} />
                        {/* {errors.password_confirmation && <p className='text-danger'>Confirm Password is required</p>} */}
                      </div>
                    </div>                                        
                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>Address <span className="login-danger">*</span>
                        </label>
                        <textarea className="form-control" type="text" placeholder="Enter Address" name="address" value={address} onChange={(e)=>{setAddress(e.target.value)}}></textarea>
                        {/* {errors.address && <p className='text-danger'>Address Number is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>City <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" placeholder="Enter City" name="city" value={city} onChange={(e)=>{setCity(e.target.value)}} />
                        {/* {errors.city && <p className='text-danger'>City is required</p>} */}
                      </div>
                    </div> 
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>State <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" placeholder="Enter State" name="state" value={state} onChange={(e)=>{setState(e.target.value)}} />
                        {/* {errors.state && <p className='text-danger'>State is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>Pincode <span className="login-danger">*</span>
                        </label>
                        <input className="form-control" type="text" placeholder="Enter Pincode" name="pincode" value={pincode} onChange={(e)=>{setPincode(e.target.value)}} />
                        {/* {errors.pincode && <p className='text-danger'>Pincode is required</p>} */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group Schools-up-files">
                       <label>Upload Schools Photo (150px X 150px)</label>
                        <div className="uplod">
                          <label className="file-upload image-upbtn mb-0">Choose File                           
                          <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} /></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="student-submit">
                        <button type="button" onClick={saveShool} className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSchool;
