import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../Config";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";



const EditSchool = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors,setErrors] = useState([]);
  const token = useSelector((state) => state.profile.token);

  const [editschool, setEditSchool] = useState({ 
    name:"",
    email:"",
    password:"",
    confirmPassword:"",
    mobile:"",
    address:"",
    city:"",
    state:"",
    pincode:"",
  });

  const {
    name,
    email,
    password,
    confirmPassword,
    mobile,
    address,
    city,
    state,
    pincode,
  } = editschool;

  const [file, setFile] = useState();
  
  const Mysingleapi = async () => {
  axios.get(`${API_URL}/schools/edit/${id}`, {

    headers: {

      Authorization: `Bearer ${token}`,

    },
    
  }).then((response)=>{
      // console.log(response.data.records.name);
      setEditSchool({
        name:response.data.records.name,
        mobile:response.data.records.mobile,
        email:response.data.records.email,
        password:response.data.records.password,
        confirmPassword:response.data.records.confirmPassword,
        address:response.data.records.address,
        city:response.data.records.city,
        state:response.data.records.state,
        pincode:response.data.records.pincode,
      });
  })
  .catch((error) => {
    console.error("Error fetching school data:", error);
  });
}

useEffect(()=>{
  Mysingleapi();
},[]);

  
  // async function saveShool()
  // {
  //   // console.log(name, email, password, confirmPassword, mobile, address, city, state, pincode, file);
  //   const formData = new FormData();
  //   formData.append("logo", file);
  //   formData.append("name", name);
  //   formData.append("email", email);
  //   formData.append("password", password);
  //   formData.append("password_confirmation", confirmPassword);
  //   formData.append("mobile", mobile);
  //   formData.append("address", address);
  //   formData.append("city", city);
  //   formData.append("state", state);
  //   formData.append("pincode", pincode);
    
  // }

  let updateObject = {
    logo : file,
    name: name,
    email:email,
    // password:password,
    // password_confirmation:confirmPassword,
    mobile:mobile,
    address:address,
    city:city,
    state:state,
    pincode:pincode,
  }

  const validateForm = () =>{
    let errors = {};
    
    if(!name){
      errors.name = "Name is required";
    }

    if(!email){
      errors.email = "Email is required";
    }

    // if(!confirmPassword){
    //   errors.confirmPassword = "Confirm Password is required";
    // }

    if(!mobile){
      errors.mobile = "Mobile is required";
    }
    
    if(!address){
      errors.address = "Address is required"
    }

    if(!city){
      errors.city = "City is required"
    }

    if(!state){
      errors.state = "State is required"
    }

    if(!pincode){
      errors.pincode = "Pincode is required"
    }

    return errors;

  }

  const updateData = async() =>{
    const errors = validateForm();
    
    if(Object.keys(errors).length === 0) 
    {
      
          try{

            const response = await axios.post(`${API_URL}/schools/${id}`,updateObject,{
              headers : {
                Authorization : `Bearer ${token}`,
                "Content-Type": "multipart/form-data", 
                    "Accept"  : "application/json", 
                }
            })
            console.log(response.data);
            toast.success(response.data.message);
            navigate("/school");
          }  
          catch(error)
          {
            console.error(error.message);
            toast.error(error.message);
          }
    }
    else
    {
      setErrors(errors);
    }
  }

  const handleChange = (event) =>{
    const {name,value} = event.target;
    setEditSchool((prevData)=>({
      ...prevData,
      [name] : value,
    }))
  }



  return (
    <>
    <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Update Schools</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="school">Schools</a>
                  </li>
                  <li className="breadcrumb-item active">Update Schools</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card comman-shadow">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="form-title student-info">
                      Schools Information{" "}
                        <span>
                          <a href="#!">
                            <i className="feather-more-vertical" />
                          </a>
                        </span>
                      </h5>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Name <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.name ? "is-invalid" : ""}`} type="text" placeholder="Enter Name" name="name" value={name} onChange={(e)=>{handleChange(e)}} />
                        {errors.name}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Mobile <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.mobile ? "is-invalid" : ""}`} type="number" placeholder="Enter Mobile Number" name="mobile" value={mobile} onChange={(e)=>{handleChange(e)}} />
                        {errors.mobile}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="form-group local-forms">
                        <label>Email <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.email ? "is-invalid" : ""}`} type="email" placeholder="Enter Email" name="email" value={email} onChange={(e)=>{handleChange(e)}} />
                        {errors.email}
                      </div>
                    </div>
                                                            
                    <div className="col-12 col-sm-12">
                      <div className="form-group local-forms">
                        <label>Address <span className="login-danger">*</span>
                        </label>
                        <textarea className={`form-control ${errors.address ? "is-invalid" : ""}`} type="text" placeholder="Enter Address" name="address" value={address} onChange={(e)=>{handleChange(e)}}></textarea>
                        {errors.address}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>City <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.city ? "is-invalid" : ""}`} type="text" placeholder="Enter City" name="city" value={city} onChange={(e)=>{handleChange(e)}} />
                        {errors.city}
                      </div>
                    </div> 
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>State <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.state ? "is-invalid" : ""}`} type="text" placeholder="Enter State" name="state" value={state} onChange={(e)=>{handleChange(e)}} />
                        {errors.state}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group local-forms">
                        <label>Pincode <span className="login-danger">*</span>
                        </label>
                        <input className={`form-control ${errors.pincode ? "is-invalid" : ""}`} type="text" placeholder="Enter Pincode" name="pincode" value={pincode} onChange={(e)=>{handleChange(e)}} />
                        {errors.pincode}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group students-up-files">
                       <label>Upload Schools Photo (150px X 150px)</label>
                        <div className="uplod">
                          <label className="file-upload image-upbtn mb-0">Choose File                           
                          <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} /></label>
                          
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="student-submit">
                        <button type="button" onClick={updateData} className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSchool;
