import React, {useState, useEffect} from 'react';
import Layout from './component/Layout';
import Login from './component/Login';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from 'react-router-dom';

const App = () => {
  // const [token,setToken] = useState(localStorage.getItem("token")); 
  const token = useSelector((state)=>state.profile.token);

  useEffect(()=>{
    // setToken(localStorage.getItem("token"));
  },[]);

  const loginRoute = (
      <Routes>
      <Route path="/login" element={<Login/>} /> 
      <Route path="/" element={<Login/>} />
      <Route path="*" element={<Login/>} />          
      </Routes>

    )
  

  return (
    <>
      <ToastContainer/> 
      
        {
         token ?
               <>
                 <Layout/>            
               </>  
              :
              loginRoute
            
        }
    </>
  )
}

export default App;
