import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import School from '../superadmin/school/School'
import AddSchool from '../superadmin/school/AddSchool'
import EditSchool from '../superadmin/school/EditSchool'
import { BloodGroup } from '../superadmin/bloodgroup/BloodGroup'
import { Category } from '../superadmin/category/Category'
import { Classes } from '../superadmin/classes/Classes'
import { Grade } from '../superadmin/grade/Grade'
import { Religion } from '../superadmin/religion/Religion'
import { Type } from '../superadmin/type/Type'
import { Sessions } from '../superadmin/sessions/Sessions';
import Login from './Login';
import { Subject } from '../superadmin/subject/Subject'



const MyRoute = () => {
  return (
        <Routes>
           <Route path="/login" element={<Login/>} />      
           <Route path="/dashboard" element={<Home/>} />      
           <Route path="/school" element={<School/>} />    
           <Route path="/school/:id" element={<EditSchool/>} />    
           <Route path="/add-school" element={<AddSchool/>} />    
           <Route path="/blood-groups" element={<BloodGroup/>} />    
           <Route path="/categories" element={<Category/>} />    
           <Route path="/classes" element={<Classes/>} />    
           <Route path="/grades" element={<Grade/>} />    
           <Route path="/religions" element={<Religion/>} />    
           <Route path="/types" element={<Type/>} />    
           <Route path="/sessions" element={<Sessions/>} />    
           <Route path="/subjects" element={<Subject/>} />    
        </Routes>  

  )
}

export default MyRoute;